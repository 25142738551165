<template>
  <section class="login">
    <div class="container">
      <div class="row">
        <div
          class="col-xx-lg-4 col-xx-lg-offset-2 col-x-lg-7 col-md-6 col-xs-12"
        >
          <div class="login__form">
            <!--  <form action="http://terapiaplus.com.ua/terapiaplus/box/login/" method="post">-->
            <h2 class="login__form-title">Увійти</h2>
            <div class="login__form-field">
              <label for="user-login">Логін</label>
              <the-mask id="user-login"
                        placeholder="Логін" mask="FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF" :tokens="hexTokens"
                        v-model="login"
              />
              <p v-if="error" class="login__error-msg">Введіть логін</p>
            </div>
            <div class="login__form-field">
              <label for="user-password">Пароль</label>
              <input
                type="password"
                id="user-password"
                placeholder="Пароль"
                v-model="password"
              />
            </div>
            <div class="login__form-options">
              <input
                type="submit"
                class="login__form-submit"
                value="Увійти"
                @click="loginUser()"
              />
              <a
                href
                class="login__form-link"
                @click.prevent="showRestorePasswordModal()"
              >Забули пароль?</a
              >
            </div>
            <div class="login__form-info">
              Ще не зареєстровані?
              <router-link to="/registration" class="login__form-link"
              >Створити обліковий запис
              </router-link
              >
            </div>
            <!--  </form>-->
          </div>
        </div>
        <div
          class="col-xx-lg-5 col-xx-lg-offset-1 col-x-lg-5 col-md-6 col-xs-12"
        >
          <div class="login__info">
            <a href="tel:0 800 211 202" class="login__info-phone"
            >0 800 211 202</a
            >
            <div class="login__info-title">
              Якщо Ви не можете увійти до свого Особистого Кабінету, будь ласка,
              зверніться до Адміністратора Програми
            </div>
            <div class="login__info-text">
              за телефоном гарячої лінії (щоденно з 9:00 до 21:00).
            </div>
            <div class="login__info-text">
              Дзвінки з усіх телефонних номерів в межах України безкоштовні.
            </div>
          </div>
        </div>
      </div>
      <!-- <form
        action="http://terapiaplus.com.ua/terapiaplus/box/login/"
        method="post"
        ref="ThemForm"
        hidden
      >
        <input type="text" name="login" v-model="login" />
        <input type="text" name="password_lo" v-model="password" />
        <input type="hidden" name="enter" value="true" />
        <input type="submit" name="submit" value="true" ref="sendThemBtn" />
      </form> -->
      <form
        id="login-form"
        class="form"
        action="https://terapiaplus.com.ua/terapiaplus/box/login/"
        method="post"
        hidden
      >
        <input
          type="text"
          name="login"
          v-model="login"
          id="username"
          class="form-control"
        />
        <input
          type="password"
          name="password_lo"
          v-model="password"
          id="password"
          class="form-control"
        />

        <input
          type="submit"
          name="enter"
          class="btn btn-info btn-md"
          value="увійти"
          ref="sendThemBtn"
        />
        <input type="hidden" name="action" value="login"/>
      </form>
    </div>
  </section>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: "Login",

  data() {
    return {
      login: "",
      password: "",
      error: false,
      hexTokens: {
        F: {
          pattern: /[0-9a-zA-Z-]/,
        }
      }
    };
  },

  methods: {
    ...mapActions(["userLogin"]),
    ...mapMutations(["setRestorePasswordModal", "showCardDoesntFind"]),
    showRestorePasswordModal() {
      this.setRestorePasswordModal(true);
    },
    loginUser() {
      this.loginValidation();

      if (!this.error) {
        let FormDataSend = new FormData()
        FormDataSend.append("login", this.login)
        FormDataSend.append("password_lo", this.password)
        FormDataSend.append("enter", "увійти")
        FormDataSend.append("action", "login")

        axios
          .post(
            "https://terapiaplus.com.ua/api/login",
            FormDataSend,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(res => {

            this.$refs.sendThemBtn.click();


          });
      }
    },
    loginValidation() {
      const replacedLogin = this.login.replace(/\s+/g, '');
      this.login = replacedLogin;
      const latinChar = this.login.substr(0, 2);
      const zeros = this.login.substr(2, 5);
      const lastNum = this.login.substr(7, 6);

      if (this.login.length !== 0) {
        this.error = false;
      } else {
        this.error = true;
      }
    }
  }
};
</script>
