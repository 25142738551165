<template>
  <div class="modal restore-password" v-show="getRestorePasswordModal">
    <div class="modal__content">
      <div class="modal__close" @click="closeRestorePasswordModal()"></div>
      <h3 class="modal__title">
        Щоб відновити пароль, будь ласка, зателефонуйте на гарячу лінію
      </h3>
      <div class="modal__main">
        <div class="modal__phone">0 800 211 202</div>
        <div class="modal__options">
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click.prevent="closeRestorePasswordModal()"
            >Зрозуміло</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "RestorePassword",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getRestorePasswordModal"])
  },
  methods: {
    ...mapMutations(["setRestorePasswordModal"]),
    closeRestorePasswordModal() {
      this.setRestorePasswordModal(false);
    }
  }
};
</script>
