<template>
  <main>
    <Login />
    <RestorePassword />
  </main>
</template>

<script>
import Login from "@/components/account//login/Login";
import RestorePassword from "@/components/modals/RestorePassword";

export default {
  name: "LoginView",
  components: {
    Login,
    RestorePassword
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/views/_login";
</style>
